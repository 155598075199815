import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import Navigation from "./navigation";

const PackageHero = ({
	title,
	description,
	image,
	imgAlt,
	imgWidth,
	h,
	hCenter,
}) => {
	return (
		<>
			<div
				Tag="section"
				className="hero-services mb-0 mb-md-5 position-relative  "
			>
				<Navigation background="none" />
				<Container className="pt-lg-6 pt-3">
					<Row className=" pt-3 px-3 align-items-start  ">
						<Col md={4} lg={5}>
							<div
								style={{ width: "14rem", height: "12rem" }}
								className="bg-background d-lg-none position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: "14rem", height: "12rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
							<div
								style={{ width: imgWidth, height: "20rem" }}
								className="bg-background  d-none d-lg-block position-relative mx-auto"
							>
								<div className="position-absolute top-10 end-10">
									<GatsbyImage
										style={{ width: imgWidth, height: "20rem" }}
										image={image}
										alt={imgAlt}
									/>
								</div>
							</div>
						</Col>
						<Col md={8} lg={6} className="pt-3  pt-md-0 ps-md-6 ps-lg-3">
							<h1
								className={` pt-3 pt-md-0 ${
									hCenter ? "text-center text-md-start" : ""
								} `}
								id="services-hero-title"
							>
								{title}
							</h1>
							{description}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default PackageHero;
